import { combineReducers } from "redux";
import { Register } from "./registerReducer";
import LoginReducer from "./loginReducer";
import PasswordResetReducer from "./resetPasswordReducer";
import BookReducer from "./Book/bookReducers";
import ExamPackageReducer from "./examPackageReducer";
import ExamPackageDetailReducer from "./examPackageDetailReducer";
import MyUpcomingExamReducer from "./myExamsReducer";
import CourseReducer from "./Course/courseReducer";
import VideoReducer from "./Course/videoReducer";
import NoteReducer from "./Course/noteReducer";
import VerifyOTPReducer from "./verifyOTPReducer";
import SingleBookReducer from "./Book/singleBookReducers";
import BannerReducer from "./bannerReducer";
import ForumReducer from "./Course/forumReducer";
import DiscussionForumReducer from "./discussionForumReducer";
import CourseExamReducer from "./Course/courseExamReducer";
import CourseExamDetailReducer from "./Course/courseExamDetailReducer";
import { fetchBoughtCourseReducer } from "./Course/BoughtCourseReducer";
import { testimonialReducer } from "./testimonialReducer";
import { BlogReducer } from "./BlogReducer";

const allReducers = combineReducers({
  register: Register,
  login: LoginReducer,
  resetPassword: PasswordResetReducer,
  verifyOTP: VerifyOTPReducer,
  books: BookReducer,
  singleBook: SingleBookReducer,
  examPackages: ExamPackageReducer,
  examPackageDetail: ExamPackageDetailReducer,
  myUpcomingExams: MyUpcomingExamReducer,
  courses: CourseReducer,
  courseVideos: VideoReducer,
  courseNotes: NoteReducer,
  courseExams: CourseExamReducer,
  coureExamDetail: CourseExamDetailReducer,
  forums: ForumReducer,
  banners: BannerReducer,
  discussionForums: DiscussionForumReducer,
  boughtCourse:fetchBoughtCourseReducer,
  testimonial:testimonialReducer,
  blog:BlogReducer
});

export default allReducers;
