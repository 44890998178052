const initialState = {
  singleBook: {},
  message: {
    success: null,
    error: null,
  },
  loading: false,
};

const SingleBookReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "FETCHING_SINGLE_BOOK":
      return {
        ...state,
        loading: true,
      };

    case "FETCHING_SINGLE_BOOK_SUCCESS":
      return {
        singleBook: payload,
        message: {
          success: "All singleBook fetched",
          error: null,
        },
        loading: false,
      };

    case "FETCHING_SINGLE_BOOK_ERROR":
      return {
        singleBook: {},
        message: {
          success: null,
          error: payload,
        },
        loading: false,
      };

    default:
      return state;
  }
};

export default SingleBookReducer;
