const initialState = {
  examPackage: {},
  message: {
    success: null,
    error: null,
  },
  loading: false,
};

const ExamPackageDetailReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "FETCHING_EXAM_PACKAGE_DETAIL":
      return {
        ...state,
        loading: true,
      };

    case "FETCHING_EXAM_PACKAGE_DETAIL_SUCCESS":
      return {
        examPackage: payload,
        message: {
          success: "All examPackages fetched",
          error: null,
        },
        loading: false,
      };

    case "FETCHING_EXAM_PACKAGE_DETAIL_ERROR":
      return {
        examPackage: {},
        message: {
          success: null,
          error: payload,
        },
        loading: false,
      };

    default:
      return state;
  }
};

export default ExamPackageDetailReducer;
