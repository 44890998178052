const initialState = {
  loading: false,
  forums: [],
  message: {
    success: null,
    error: null,
  },
};

const ForumReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "FETCHING_FORUM":
      return {
        ...state,
        loading: true,
      };

    case "FETCH_FORUM_SUCCESS":
      return {
        ...state,
        loading: false,
        forums: payload,
        message: {
          success: "Forums has been fetched successfully",
          error: null,
        },
      };

    case "FETCHING_FORUM_ERROR":
      return {
        ...state,
        loading: false,
        message: {
          success: null,
          error: "Failed to fetch forum",
        },
      };

    default:
      return state;
  }
};

export default ForumReducer;
