const initialState = {
  loading: false,
  notes: [],
  message: {
    success: null,
    error: null,
  },
  singleNote: {},
};

const NoteReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "FETCHING_NOTE":
      return {
        ...state,
        loading: true,
      };

    case "FETCH_NOTE_SUCCESS":
      return {
        ...state,
        loading: false,
        notes: payload,
        message: {
          success: "Notes has been fetched successfully",
          error: null,
        },
      };

    case "FETCHING_NOTE_ERROR":
      return {
        ...state,
        loading: false,
        message: {
          success: null,
          error: "Failed to fetch note",
        },
      };

    default:
      return state;
  }
};

export default NoteReducer;
