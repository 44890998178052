import "./App.css";
import Loader from "./components/Loader/Loader";
import React, { Suspense, useState } from "react";
import ScrollToTop from "./helper/ScrollToTop";
const Navbar = React.lazy(() => import("./components/Navbar/Navbar"));
const AllRoutes = React.lazy(() => import("./routes/AllRoutes"));
const Footer = React.lazy(() => import("./components/Footer/Footer"));

function App() {
  return (
    <Suspense fallback={<Loader />}>
      <ScrollToTop />
      <Navbar />
      <AllRoutes />
      
      </Suspense>
     
  );
}

export default App;
