const initialState = {
  myExams: [],
  message: {
    success: null,
    error: null,
  },
  loading: false,
};

const MyUpcomingExamReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "FETCHING_MY_EXAMS":
      return {
        ...state,
        loading: true,
      };

    case "FETCHING_MY_EXAMS_SUCCESS":
      return {
        myExams: payload,
        message: {
          success: "All myExams fetched",
          error: null,
        },
        loading: false,
      };

    case "FETCHING_MY_EXAMS_ERROR":
      return {
        myExams: [],
        message: {
          success: null,
          error: payload,
        },
        loading: false,
      };

    default:
      return state;
  }
};

export default MyUpcomingExamReducer;
